<template>
  <v-fade-transition leave-absolute>
    <div
      :key="counter"
      class="d-flex align-center overflow-hidden logo-bar-fade"
      style="height: 100px"
    >
      <div v-for="item in items" :key="item.id">
        <a
          v-if="item.href"
          :href="item.href"
          target="_blank"
          class="mr-10 d-flex justify-center"
        >
          <LogoImage
            v-bind="props"
            pin-width
            :alt="`Logo for ${item.organization.name}`"
            :value="item.organization.logo_white"
          />
        </a>
        <div v-else class="mr-10 d-flex justify-center">
          <LogoImage
            v-bind="props"
            pin-width
            :alt="`Logo for ${item.organization.name}`"
            :value="item.organization.logo_white"
          />
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script setup>
import { ref } from 'vue'
import { useIntervalFn } from '@vueuse/core'
import shuffle from 'lodash.shuffle'

const UPDATE_INTERVAL = 10 * 1000 // 10s

const props = defineProps({
  invert: {
    default: false,
    type: Boolean
  },
  opacity: {
    default: 1,
    type: Number
  },
  value: {
    required: true,
    type: Array
  }
})

const counter = ref(0)
const items = ref(
  props.value.filter(item => item.organization && item.organization.logo_white)
)

useIntervalFn(() => {
  counter.value++
  items.value = shuffle(items.value)
}, UPDATE_INTERVAL)
</script>

<style>
.logo-bar-fade {
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    black 8% 92%,
    transparent
  );
  mask-image: linear-gradient(to right, transparent, black 8% 92%, transparent);
}
</style>
